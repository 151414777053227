.ambient::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    transform: translate(-50%, -50%);
    background: var(--ambient);
    z-index: 1;
    @apply blur-xl;
}
