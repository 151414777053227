@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Video;
    src: local(Video-SemiBold),
      url(./assets/fonts/Video-SemiBold.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: Video-Bold;
    font-weight: 700;
    font-style: bold;
    font-display: swap;
    src: local(Video-Bold),
      url(./assets/fonts/Video-Bold.woff2) format('woff2');
  }

  @font-face {
    font-family: Video-Medium;
    font-weight: 400;
    font-style: medium;
    font-display: swap;
    src: local(Video-Medium),
      url(./assets/fonts/Video-Medium.woff2) format('woff2');
  }

  @font-face {
    font-family: Video-Light;
    font-weight: 300;
    font-style: light;
    font-display: swap;
    src: local(Video-Light),
      url(./assets/fonts/Video-Light.woff2) format('woff2');
  }

  @font-face {
    font-family: Video-Regular;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: local(Video-Regular),
      url(./assets/fonts/Video-Regular.woff2) format('woff2');
  }
}

body {
  margin: 0;
  font-family: Video;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Video;
}

@layer components {
  .timer {
    @apply text-3xl font-video text-center tracking-wide font-medium text-default-shadow-2;
  }
  .text-style-3 {
    @apply text-3xl font-video text-center tracking-wide font-medium text-default-shadow-2;
  }

  .refferals-coins {
    @apply text-2xl font-video text-center tracking-tight font-bold text-add-coins;
  }

  .head-1 {
    @apply text-xl font-video text-center font-semibold text-border-progress;
  }

  .head-2 {
    @apply font-video tracking-wide font-semibold text-border-progress;
  }

  .loadpage-2 {
    @apply font-video leading-tight text-center tracking-wide text-border-progress;
  }

  .score-2 {
    @apply text-sm font-video tracking-wide font-bold text-score-good;
  }

  .score-3 {
    @apply text-sm font-video tracking-wide font-bold text-score-bad;
  }

  .score-levels-count {
    @apply text-sm font-video tracking-wide font-bold text-border-progress;
  }

  .ton-1 {
    @apply text-sm font-video text-center font-bold text-ton-color;
  }

  .percent-1 {
    @apply text-sm font-video tracking-wide font-medium text-border-progress;
  }

  .table-head {
    @apply text-sm font-video tracking-wide text-border-progress;
  }

  .level-name {
    @apply text-sm font-video font-light text-level-on-game-field;
  }

  .head-sub {
    @apply text-xs font-video leading-normal tracking-wide text-border-progress;
  }

  .text-style-5 {
    @apply text-xs font-video text-center font-light text-border-progress;
  }

  .ref-level {
    @apply text-xs font-video font-light text-level-3;
  }

  .ref-level-2 {
    @apply text-xs font-video font-light text-level-on-game-field;
  }

  .score-levels-type {
    @apply text-xs font-video text-center font-light text-border-progress;
  }

  .menu-button {
    @apply text-sm font-video tracking-wide font-medium w-[85%] bg-opacity-70 rounded-md h-[3rem] -skew-x-[3deg] bg-gradient-to-b from-bg-gradient-1/70 to-bg-gradient-2/70 my-2 cursor-pointer flex items-center px-[1.288rem] border-t-2 border-white/30 shadow-xl transition-all ease-in-out delay-300 hover:bg-opacity-90 hover:scale-105;
  }
}