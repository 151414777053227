.background {
    background: url(../assets//images/load_bg_min.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
}

UL {
    position: absolute;
    left: -1em;
    right: -1em;
    top: -1em;
    bottom: -1em;
    margin: auto;
    padding: 0;
    width: 0;
    height: 0;
    list-style: none;
    z-index: -1;
    transform: translateY(30vh);
}

LI,
LI:before,
LI:after {
    position: absolute;
    border: 0 solid transparent;
    border-width: 1.2em 20em;
    border-color: transparent rgba(164, 216, 248, 0.5);
    width: 0;
    height: 0;
    font-size: 40px;
    opacity: 0.4;
    filter: blur(2px);
    transform-origin: center center;
}

LI {
    left: -20em;
    top: 50%;
    margin-top: -1.2em;
    transform: rotate(.5deg);
}

LI:before,
LI:after {
    left: -20em;
    top: -1.2em;
    display: block;
    content: "";
}

LI:before {
    transform: rotate(35deg);
}

LI:after {
    transform: rotate(-36deg);
}

/* LI:nth-child(2) {
    transform: rotate(165deg);
}

LI:nth-child(2),
LI:nth-child(2):before,
LI:nth-child(2):after {
    opacity: 0.6;
} */

LI:nth-child(2) {
    transform: rotate(75deg);
}

LI:nth-child(2),
LI:nth-child(2):before,
LI:nth-child(2):after {
    opacity: 0.8;
}

