.not-avatar {
    position: relative;
    border-radius: 50%;
}

.not-avatar::before {
    content: '';
    position: absolute;
    width: 105%;
    height: 105%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 50%;
    background: linear-gradient(180deg, #247ec1 0%, #121620 100%);
}

.not-avatar::after {
    content: attr(data-name);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.001em;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}