.field {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
}

/* .field :first-child:first-child, 
.field :last-child:last-child {
    background: #05e6f271;
} */

.field :first-child:first-child::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: translate(50%, 50%);
    border: #deeff071 3px solid;
    background: #05e6f271;
}

.field :nth-last-child(4)::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: translate(50%, 50%);
    border: #deeff071 3px solid;
    background: #05e6f271;
}

.field :nth-child(10)::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: translate(50%, 50%);
    border: #deeff071 3px solid;
    background: #05e6f271;
}

.field :nth-last-child(13)::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: translate(50%, 50%);
    border: #deeff071 3px solid;
    background: #05e6f271;
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}