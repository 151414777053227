.bg-menu {
    @apply h-2/3 rounded-t-md bg-gray-800 mt-auto;
}


.disabled-menu {
  pointer-events: none;
  opacity: 0.5;
}

.ms-not-left::before {
    content: '';
    position: absolute;
    height: 4rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    border-radius: 50%;
    background: url('../assets/images/ms_left_elem.webp') no-repeat;
    background-size: contain;
    background-position: center;
    transform: translateX(-50%) translateY(-50%);
}