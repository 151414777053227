.value-line {
    position: relative;
}

.value-line::before {
    content: '';
    background-image: url('../../assets/images/BlueCoin.webp');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 5rem;
    height: 5rem;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(50%) translateY(-48%);
    transition: all 0.3s ease-in-out;
}
