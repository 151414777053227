.menu-btn {
    transition: all 0.2s ease-in-out;
}
.menu-btn:hover {
    transform: scale(1.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.menu-btn:active {
    transform: scale(0.90);
    box-shadow: none;
}
